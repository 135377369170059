import "./intro.scss";
import { init } from "ityped";
import { useEffect, useRef } from "react";

export default function Intro() {
  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, {
      showCursor: true,
      backDelay: 1500,
      backSpeed:60,
      strings: ["Coder", "Student", "Obsessed with learning! :)", "Inquisitive", "Open-minded"],
    });
  }, []);

  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="imgContainer">
          <img src="assets/img.jpg" alt="" />
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <h2>Hi! I'm</h2>
          <h1>Agrima Kampani</h1>
          <h3>
            <span ref={textRef}></span>
          </h3>
          <span><b>Enthusiastic and determined student majoring in Computer Science.</b></span>
          <span>Frameworks and Libraries: React.js, Node.js/Express.js, MongoDB, Bootstrap</span>
          <span>Programming Languages: Java, C, C++, JavaScript, Python, HTML, CSS, Linux, R</span>
          <span>Special Interests: Software Engineering, Machine Learning</span>
        </div>
        <a href="#portfolio">
          <img src="assets/down.png" alt="" />
        </a>
      </div>
    </div>
  );
}
