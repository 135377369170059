
import "./contact.scss";

export default function Contact() {

  return (
    <div className="contact" id="contact">
      <div className="left">
        <img src="assets/shake.svg" alt="" />
      </div>
      <div className="right">
        <h4>Feel free to shoot me a message if you want to know about projects I've done! :)</h4>
        <h4>More than happy to hop on a call!</h4>
        <h4>Contact: agrimakampani@gmail.com</h4>
      </div>
    </div>
  );
}
